// @ngInject
function isPasswordAuthed(acpAuthModel) {
  return acpAuthModel.getInfo().then(
    function (data) {
      return data.password;
    },
    function () {
      return false;
    }
  );
}

// @ngInject
function isSmallBusiness(acpFeatureModel, $q) {
  return $q
    .all({
      master: acpFeatureModel.hasFeature('pubweb:businessaccount'),
      sub: acpFeatureModel.hasFeature('pubweb:businesssubaccount')
    })
    .then(
      function (features) {
        return features.master || features.sub;
      },
      function () {
        return false;
      }
    );
}

// @ngInject
function isPayPal(nsConfig, nsUtil, $q) {
  var brand = nsUtil.isString(nsConfig.get('brand'))
    ? nsConfig.get('brand').toLowerCase()
    : nsConfig.get('brand');

  return $q.resolve(brand === 'paypal');
}

// @ngInject
function hasNotificationCenter(nsConfig, $q) {
  return $q.resolve(nsConfig.get('hasNotificationCenter') === true);
}

// @ngInject
function isExternalLoyaltyEligible(nsConfig) {
  // TODO: Replace with real WebAPI feature call when it's ready
  return nsConfig.get('brand') === 'gianteagle';
}

// @ngInject
function isNetspendDisbursement(
  $q,
  NS_PROGRAM_TYPES,
  acpCoreDispatcher,
  acpSimpleAccountModel
) {
  var account = acpCoreDispatcher.profile.simpleAccount.updated.getValue();

  if (account && account.program.type) {
    return $q.resolve(
      account.program.type === NS_PROGRAM_TYPES.NETSPEND_DISBURSEMENT
    );
  } else {
    return acpSimpleAccountModel.get().then(function () {
      account = acpCoreDispatcher.profile.simpleAccount.updated.getValue();
      return $q.resolve(
        account.program.type === NS_PROGRAM_TYPES.NETSPEND_DISBURSEMENT
      );
    });
  }
}

// @ngInject
function hasIngoSdk($q, $window) {
  return $q.resolve(!!$window.ingosdk);
}

// @ngInject
function isEmbeddedMobile($q, acpDeviceService) {
  // This should be changed when we differentiate between hosted and embedded mobile
  return $q.resolve(acpDeviceService.isCordova());
}

// @ngInject
function isNsSmallBizBrand(nsConfig) {
  switch (nsConfig.get('brand')) {
    case 'netspendbusiness':
    case 'completesmallbiz':
      return true;
    default:
      return false;
  }
}

// @ngInject
function isAccountWithoutCard(acpFeatureModel, $q) {
  return $q
    .all({
      master: acpFeatureModel.hasFeature('pubweb:businessaccount'),
      expense: acpFeatureModel.hasFeature('webapi:is_expense_user')
    })
    .then(
      function (features) {
        return features.expense && features.master;
      },
      function () {
        return false;
      }
    );
}

// @ngInject
function isVariantSunset($q) {
  // Placeholder incase any variant/tenant needs to be sunset in the future
  return $q.resolve(false);
}

// @ngInject
function isBiometricsAvailable(acpBiometricsLoginDomainClient) {
  return acpBiometricsLoginDomainClient.isAvailable();
}

// @ngInject
function hasRestrictions(acpAccountsModel) {
  return acpAccountsModel.getRestrictions().then(function (data) {
    return data.restrictions.length > 0;
  });
}

// @ngInject
function isSecurityVerified(acpAuthModel) {
  var securityData = acpAuthModel.info.getValue();
  return securityData.questions || securityData.ooba;
}

function resolveBoolean(promise) {
  return promise.then(
    function (val) {
      return !!val;
    },
    function () {
      return false;
    }
  );
}

/* @ngInject */
function hasMultipleUpgradeCardBrands(acpUpgradeOrderCardClient) {
  return acpUpgradeOrderCardClient.getCardBrands().then(function (response) {
    return response.length && response.length > 1;
  });
}

/**
 * Helper function that combines permissions in an OR fashion. All the args are expected to be functions that
 * evaluate a permission. If any of them resolve true, then this permission resolves true.
 */
function or(/* ...args */) {
  var permissions = Array.prototype.slice.call(arguments);
  // @ngInject
  function combineOr($injector, $q) {
    return $q
      .all(
        permissions.map(function (permission) {
          return resolveBoolean($injector.invoke(permission));
        })
      )
      .then(function (results) {
        return results.some(function (value) {
          return value;
        });
      });
  }
  return combineOr;
}

/**
 * Helper function that combines permissions in an AND fashion. All the args are expected to be functions that
 * evaluate a permission. If all of them resolve true, then this permission resolves true.
 */
function and(/* ...args */) {
  var permissions = Array.prototype.slice.call(arguments);
  // @ngInject
  function combineAnd($injector, $q) {
    return $q
      .all(
        permissions.map(function (permission) {
          return resolveBoolean($injector.invoke(permission));
        })
      )
      .then(function (results) {
        return results.every(function (value) {
          return value;
        });
      });
  }
  return combineAnd;
}

/**
 * Helper function that not's a permission
 */
function not(permission) {
  // @ngInject
  function notOperator($injector) {
    return resolveBoolean($injector.invoke(permission)).then(function (result) {
      return !result;
    });
  }
  return notOperator;
}
/**
 * Creates a Loopback Delegate around the nsPermissions Client and removes the need
 * for recreating nsPermissions, especially when using combinators, and / or.
 *
 * @param feature - the feature to check
 * @returns {featureDelegateEnabled}
 */
function nsPermissionDelegate(permission) {
  // @ngInject
  function permissionDelegate(nsPermissions) {
    return nsPermissions.requestPermission(permission);
  }

  return permissionDelegate;
}

/**
 * Creates a Delegate around the ACP Feature Client and removes the need
 * for recreating the same function over and over again...
 *
 * @param feature - the feature to check
 * @returns {featureDelegateEnabled}
 */
function acpFeatureDelegate(feature) {
  // @ngInject
  function featureDelegateEnabled(acpFeatureModel) {
    return acpFeatureModel.hasFeature(feature);
  }

  return featureDelegateEnabled;
}

/**
 * Creates a Delegate around the nsConfig services and removes the need
 * for recreating the same function over and over again...
 *
 * @param configKey - the config key to check
 * @returns {nsConfigDelegateEnabled}
 */
function acpConfigDelegate(configKey) {
  // @ngInject
  function nsConfigDelegateEnabled($q, nsConfig) {
    return $q.resolve(!!nsConfig.get(configKey));
  }

  return nsConfigDelegateEnabled;
}

export default {
  accountHasSubaccounts: acpFeatureDelegate('account_has_subaccounts'),
  isFamilyCardsTransfersEligible: acpFeatureDelegate(
    'isFamilyCardsTransfersEligible'
  ),
  allowHub2GprUpgrade: acpFeatureDelegate('webapi:allow_hub_2_gpr_upgrade'),
  allowOverdraftWithoutEsign: acpFeatureDelegate(
    'webapi:allow_overdraft_without_esign'
  ),
  anyFreeAtmNetwork: acpFeatureDelegate('webapi:anyfreeatmnetwork'),
  autoDirectDepositEligible: acpFeatureDelegate(
    'webapi:auto_direct_deposit_eligible'
  ),
  isMasterAccountAndAcceptedEsignTerms: and(
    acpFeatureDelegate('webapi:AlertsTermsAccepted'),
    acpFeatureDelegate('AnytimeAlertsMasterAccountManagement')
  ),
  disallowEsignOptOut: acpFeatureDelegate('webapi:disallow_optout_esign'),
  displayFreeAtmCount: acpFeatureDelegate('webapi:display_free_atm_count'),
  hasActivatedDebitCard: acpFeatureDelegate('pubweb:hasactivateddebitcard'),
  hasAlertsTermsAccepted: acpFeatureDelegate('webapi:AlertsTermsAccepted'),
  oac4AlertsEnabled: acpFeatureDelegate('webapi:oac4_anytime_alerts_enabled'),
  isAnyTimeAlertBizMobileEnabled: acpFeatureDelegate(
    'isAnyTimeAlertBizMobileEnabled'
  ),
  oac4PremierEnabled: acpFeatureDelegate('webapi:oac4_premier_enabled'),
  hasAllowVirtualCardsWithoutAPersoCard: acpFeatureDelegate(
    'pubweb:AllowVirtualCardsWithoutAPersoCard'
  ),
  hasVirtualCardsWithoutPeronsalizedCard: acpFeatureDelegate(
    'hasVirtualCardsWithoutPeronsalizedCard'
  ),
  hasAnytimeAlertsMasterAccountManagement: acpFeatureDelegate(
    'AnytimeAlertsMasterAccountManagement'
  ),
  hasBankTransfer: acpFeatureDelegate('webapi:BankTransfer'),
  hasBankTransferButDisabled: acpFeatureDelegate('hasBankTransferButDisabled'),
  hasBankTransferAndEnabled: acpFeatureDelegate('hasBankTransferAndEnabled'),
  isOACBankLinkRerouteEnabled: acpFeatureDelegate('OACBankLinkRerouteEnabled'),
  hasBillPay: acpFeatureDelegate('pubweb:BillPay'),
  hasBillPayExpressPay: acpFeatureDelegate('pubweb:billpayexpresspay'),
  hasCanEditProfile: acpFeatureDelegate('pubweb:CanEditProfile'),
  hasCanReportLostStolen: acpFeatureDelegate('pubweb:CanReportLostStolen'),
  hasCanViewContactInfo: acpFeatureDelegate('pubweb:CanViewContactInfo'),
  isCardLostStolenEnabled_oac3: acpFeatureDelegate(
    'isCardLostStolenEnabled_oac3'
  ),
  isCardLostStolenEnabled: acpFeatureDelegate('isCardLostStolenEnabled'),
  isContactBizEnabled: acpFeatureDelegate('isContactBizEnabled'),
  hasCrossSell: acpFeatureDelegate('pubweb:CrossSell'),
  hasCustomCard: acpFeatureDelegate('pubweb:CustomCard'),
  hasCustomerInbox: acpFeatureDelegate('pubweb:CustomerInbox'),
  isOac4CustomCardEnabled: acpFeatureDelegate(
    'webapi:oac4_custom_card_enabled'
  ),
  hasDirectDepositAvailable: acpFeatureDelegate('pubweb:DirectDeposit'),
  hasFeedback: acpFeatureDelegate('webapi:feedback'),
  isOac4FeedbackEnabled: acpFeatureDelegate('oac4_feedback_enabled'),
  isOac4FeedbackDisabledMobile: and(
    not(nsPermissionDelegate('isOac4FeedbackEnabled')),
    nsPermissionDelegate('isEmbeddedMobile')
  ),
  hasFeePlans: acpFeatureDelegate('webapi:FeePlans'),
  canManageFeePlans: acpFeatureDelegate('webapi:CanManageFeePlans'),
  hasManageFeePlanSidenavItem: acpFeatureDelegate(
    'hasManageFeePlanSidenavItem'
  ),
  canManagePricingFeePlanSidenavItem: acpFeatureDelegate(
    'canManagePricingFeePlanSidenavItem'
  ),
  hasFileDispute: acpFeatureDelegate('pubweb:FileDispute'),
  hasHasVirtualCard: acpFeatureDelegate('pubweb:HasVirtualCard'),
  hasMoneyOrder: acpFeatureDelegate('pubweb:MoneyOrder'),
  hasNotificationCenter: hasNotificationCenter,
  hasOrderCard: acpFeatureDelegate('pubweb:OrderCard'),
  isOptionalPersoCardFeatureEnabled: acpFeatureDelegate(
    'webapi:OptionalPersoCard'
  ),
  hasOrderCardsEnabled: acpFeatureDelegate('hasOrderCardsEnabled'),
  hasOverdraft: acpFeatureDelegate('pubweb:Overdraft'),
  overDraftSummaryUpdatesKillSwitch: acpFeatureDelegate(
    'pubweb:overdraft_summary_update_kill_switch'
  ),
  hasPayPal: acpFeatureDelegate('pubweb:Paypal'),
  hasPaypalPayments: acpFeatureDelegate('webapi:paypal_payments'),
  hasPaypalPaymentsForLinkedAccount: acpFeatureDelegate(
    'hasPaypalPaymentsForLinkedAccount'
  ),
  hasPaypalPaymentsForUnlinkedAccount: acpFeatureDelegate(
    'hasPaypalPaymentsForUnlinkedAccount'
  ),
  hasPersonalFinanceToolsBudgeting: acpFeatureDelegate(
    'pubweb:PersonalFinanceToolsBudgeting'
  ),
  hasPremierEligible: acpFeatureDelegate('webapi:PremierEligible'),
  hasReferAFriend: acpFeatureDelegate('pubweb:ReferAFriend'),
  oac4ReferAFriendEnabled: acpFeatureDelegate(
    'webapi:oac4_refer_a_friend_enabled'
  ),
  hasReloadsAllowed: acpFeatureDelegate('pubweb:ReloadsAllowed'),
  hasRequestFunds: acpFeatureDelegate('pubweb:RequestFunds'),
  hasSavings: acpFeatureDelegate('pubweb:savings'),
  isSavingsEligibleOnly: acpFeatureDelegate('isSavingsEligibleOnly'),
  isSavingsEligibleAndEnrolled: acpFeatureDelegate(
    'isSavingsEligibleAndEnrolled'
  ),
  isPremierSavingsEligibleAndEnrolled: acpFeatureDelegate(
    'isPremierSavingsEligibleAndEnrolled'
  ),
  isFileADisputeAvailable: acpFeatureDelegate('file_dispute_available'),
  isPremierEligibleOnly: acpFeatureDelegate('isPremierEligibleOnly'),
  hasSavingsEligible: acpFeatureDelegate('webapi:savings_eligible'),
  hasSavingsEnrolled: acpFeatureDelegate('webapi:savings_enrolled'),
  hasSavingsStatements: acpFeatureDelegate('webapi:savings_statements'),
  hasSendMoneyGateway: acpFeatureDelegate('webapi:send_money_gateway'),
  hasToursAvailable: acpFeatureDelegate('webapi:guided_tours_eligible'),
  hasTransferNowEligibility: acpFeatureDelegate(
    'pubweb:TransferNowEligibility'
  ),
  hasTransferNowEligible: acpFeatureDelegate('webapi:transfer_now_eligible'),
  hasMeToMeTransferEnabled: acpFeatureDelegate('webapi:oac4_me_to_me_enabled'),
  hasFileDisputeEnabled: acpFeatureDelegate('webapi:oac4_file_dispute_enabled'),
  hasFileDisputeEnabledInLean: acpFeatureDelegate(
    'webapi:isAdjustmentHubSupportedInOAC'
  ),
  isMoneyGramAvailable: acpFeatureDelegate('webapi:money_gram_available'),
  hasActiveDebitCards: acpFeatureDelegate('webapi:ActiveDebitCardLifecycle'),
  hasVirtualCardOnlyAccount: acpFeatureDelegate(
    'pubweb:VirtualCardOnlyAccount'
  ),
  hasVirtualCardAccount: acpFeatureDelegate('hasVirtualCardAccount'),
  hasVirtualDebitCardsAllowed: acpFeatureDelegate(
    'pubweb:VirtualDebitCardsAllowed'
  ),
  hasVirtualDebitCards: acpFeatureDelegate('hasVirtualDebitCards'),
  hasWirelessAlerts: acpFeatureDelegate('pubweb:WirelessAlerts'),
  isAtmFinderEnabled: acpFeatureDelegate('webapi:oac4_atm_finder_enabled'),
  isBiometricsAvailable: and(
    isBiometricsAvailable,
    acpFeatureDelegate('webapi:biometrics_login_enabled')
  ),
  isFaceAuthEnabled: acpFeatureDelegate('webapi:face_auth_enabled'),
  isBenefitsCenterEligible: acpFeatureDelegate(
    'webapi:isBenefitsCenterEligible'
  ),
  bcPurchaseAssurance: acpFeatureDelegate('acp:bc-purchase-assurance'),
  bcRideshareProtection: acpFeatureDelegate('acp:bc-rideshare-protection'),
  hidePurchaseBenefitsHeading: acpFeatureDelegate(
    'acp:hide-purchase-benefits-heading'
  ),
  isCardlyticsEligible: acpFeatureDelegate('webapi:CardlyticsEligible'),
  isCDDA: acpFeatureDelegate('webapi:cdda_account_type'),
  isDDA: acpFeatureDelegate('webapi:is_dda'),
  isDdaActivationEligible: acpFeatureDelegate(
    'webapi:cdda_upgrade_activation_eligible'
  ),
  isBddaActivationEligible: acpFeatureDelegate(
    'webapi:bdda_upgrade_activation_eligible'
  ),
  isDdaUpgradeEligible: acpFeatureDelegate('webapi:cdda_upgrade_eligible'),
  isBddaUpgradeEligible: acpFeatureDelegate('webapi:bdda_upgrade_eligible'),
  isUpgradedAccount: acpFeatureDelegate('webapi:is_upgraded_account'),
  isDebitCardTransfersEligible: acpFeatureDelegate(
    'webapi:debit_card_transfer_eligible'
  ),
  isPushDebitCardTransferEligible: acpFeatureDelegate(
    'webapi:push_money_debit_card_transfer_eligible'
  ),
  isPullDebitCardTransferEligible: acpFeatureDelegate(
    'webapi:pull_money_debit_card_transfer_eligible'
  ),
  canAddExternalCard: acpFeatureDelegate('webapi:additionaldctcardallowed'),
  isDigitalWalletEnabled: acpFeatureDelegate(
    'webapi:is_digital_wallet_enabled'
  ),
  isDirectDepositEnrolled: acpFeatureDelegate('webapi:ddenrolled'),
  isNotDirectDepositEnrolled: acpFeatureDelegate('isNotDirectDepositEnrolled'),
  isFaqEnabled: acpFeatureDelegate('isFaqEnabled'),
  isFaqBizEnabled: acpFeatureDelegate('isFaqBizEnabled'),
  isOrderAdditionalCardsEnabled: acpFeatureDelegate(
    'isOrderAdditionalCardsEnabled'
  ),
  isFeedbackEnabled: acpFeatureDelegate('isFeedbackEnabled'),
  isDynamicFaqEnabled: acpFeatureDelegate('webapi:dynamic_faq_enabled'),
  hasDynamicFaqEnabled: acpFeatureDelegate('hasDynamicFaqEnabled'),
  hasDynamicFaqBizEnabled: acpFeatureDelegate('hasDynamicFaqBizEnabled'),
  isDynamicFaqEnabledAndSmallBiz: and(
    acpFeatureDelegate('webapi:dynamic_faq_enabled'),
    isSmallBusiness
  ),
  isExternalLoyaltyEligible: isExternalLoyaltyEligible,
  isFeeAdvantageEligible: acpFeatureDelegate('pubweb:feeadvantageeligible'),
  isFreeAtmNetworkAvailable: acpFeatureDelegate('pubweb:anyfreeatmnetwork'),
  isGprSubaccount: acpFeatureDelegate('GPRSubaccount'),
  dashboardEnabled: acpFeatureDelegate('dashboardEnabled'),
  isHubUser: acpFeatureDelegate('webapi:is_hub_user'),
  useDashboardLite: acpFeatureDelegate('webapi:use_dashboard_lite'),
  privacyPolicyEnabled: acpFeatureDelegate('privacyPolicyEnabled'),
  isSidenavFeaturesGroupEnabled: acpFeatureDelegate(
    'isSidenavFeaturesGroupEnabled'
  ),
  isHubRegistrationEnabled: acpFeatureDelegate(
    'webapi:hub_registration_enabled'
  ),
  isIngoEligible: acpFeatureDelegate('webapi:mcl_ingo_eligible'),
  isLocationFinderEnabled: acpFeatureDelegate(
    'webapi:oac4_reload_location_finder_enabled'
  ),
  isLoyaltyLearnMoreEnabled: acpFeatureDelegate('loyaltyLearnMoreEnabled'),
  isMcl: acpFeatureDelegate('pubweb:mcl'),
  isMobileCheckAvailable: acpFeatureDelegate('webapi:mcl_ingo_available'),
  isIngoBusinessVerificationRequired: acpFeatureDelegate(
    'webapi:ingo_business_verification_required'
  ),
  isMobileApp: acpFeatureDelegate('isMobileApp'),
  isMoveMoneyEligible: acpFeatureDelegate('webapi:move_money_eligible'),
  isOnboardingAvailable: acpFeatureDelegate('webapi:is_onboarding_available'),
  isOverdraftAvailable: acpFeatureDelegate('webapi:overdraft_available'),
  isPasswordAuthed: isPasswordAuthed,
  isPaybackRewardsEligible: acpFeatureDelegate(
    'webapi:payback_rewards_eligible'
  ),
  isPaybackRewardsOptedIn: acpFeatureDelegate(
    'webapi:payback_rewards_opted_in'
  ),
  isDefaultPayNearMe: acpFeatureDelegate('isDefaultPayNearMe'),
  isHubPayNearMe: acpFeatureDelegate('isHubPayNearMe'),
  isPayNearMeEligible: acpFeatureDelegate('webapi:pay_near_me_eligible'),
  isPayNearMeEnrolled: acpFeatureDelegate('webapi:pay_near_me_enrolled'),
  isPayPal: isPayPal,
  isPaypalLinkedAccount: acpFeatureDelegate('paypal_linked_account'),
  isPaypalUnlinkedAccount: acpFeatureDelegate('paypal_unlinked_account'),
  allowNSCredentialsForPaypal: acpFeatureDelegate(
    'pubweb:paypal_allow_ns_credentials'
  ),
  isPointsEligible: acpFeatureDelegate('webapi:loyalty_points_eligible'),
  isPointsEnrolled: acpFeatureDelegate('webapi:loyalty_points_enrolled'),
  isLoyaltyPointsOptoutAllowed: acpFeatureDelegate(
    'webapi:loyalty_points_optout_allowed'
  ),
  isSidenavPrefundedChecksEnabled: acpFeatureDelegate(
    'isSidenavPrefundedChecksEnabled'
  ),
  isPrefundedChecksEligible: acpFeatureDelegate(
    'webapi:prefunded_checks_eligible'
  ),
  isPrefundedChecksEligibleOrRemainingChecks: acpFeatureDelegate(
    'webapi:prefunded_checks_eligible_or_remaining_checks'
  ),
  isPrefundedChecksManageEligible: acpFeatureDelegate(
    'webapi:prefunded_checks_manage_eligible'
  ),
  isPrefundedChecksAssociated: acpFeatureDelegate(
    'isPrefundedChecksAssociated'
  ),
  isPremier: acpFeatureDelegate('pubweb:Premier'),
  isPremierEligible: acpFeatureDelegate('pubweb:premiereligible'),
  isProductUpgradeActivationEligible: acpFeatureDelegate(
    'webapi:product_upgrade_activation_eligible'
  ),
  isProductUpgradeIbaEligible: acpFeatureDelegate(
    'webapi:product_upgrade_iba_eligible'
  ),
  isProductUpgradeOrdered: acpFeatureDelegate('webapi:cdda_upgrade_ordered'),
  isVariantSunset: isVariantSunset,
  isSavingsAvailable: acpFeatureDelegate('webapi:savings_available'),
  isSavingsEligible: acpFeatureDelegate('pubweb:savingseligible'),
  isSavingsEnrolled: acpFeatureDelegate('pubweb:savingsenrolled'),
  isSavingsW9Compliant: acpFeatureDelegate('webapi:savings_w9_compliant'),
  isSendMoneyAvailable: acpFeatureDelegate('webapi:send_money_available'),
  isSidenavRafEnabled: acpFeatureDelegate('webapi:sidenav_raf_enabled'),
  isSendEmailRafEnabled: acpFeatureDelegate('webapi:refer_a_friend_email'),
  isSkylightAddMoneyEligible: acpFeatureDelegate(
    'webapi:no_transactions_can_add_money'
  ),
  isSkylightCheckEligible: acpFeatureDelegate(
    'webapi:is_SkylightCheck_eligible'
  ),
  isSmallBusiness: isSmallBusiness,
  isSmallBusinessMaster: acpFeatureDelegate('pubweb:businessaccount'),
  isSmallBusinessSub: acpFeatureDelegate('pubweb:businesssubaccount'),
  isSpeedWay: acpFeatureDelegate('webapi:SpeedyRewards'),
  isSpeedWayPointsEligible: acpFeatureDelegate('isSpeedWayPointsEligible'),
  isSpeedWayNotPointsEligible: acpFeatureDelegate(
    'isSpeedWayNotPointsEligible'
  ),
  isTransferNowAvailable: acpFeatureDelegate('webapi:TransferNowAvailable'),
  isTransferNowEligible: acpFeatureDelegate('pubweb:transfernoweligiblity'),
  isSideNavCloseAccountEnabled: acpFeatureDelegate(
    'isSidenavCloseAccountEnabled'
  ),
  isOac4CloseAccountEnabled: acpFeatureDelegate('close_account_enabled'),
  isBankTransfersEnabled: acpFeatureDelegate('oac4_bank_transfers_enabled'),
  isWesternUnionAvailable: acpFeatureDelegate('webapi:wu_available'),
  isWuEligible: acpFeatureDelegate('webapi:wu_eligible'),
  netspendFeeFreePartnerAtms: acpFeatureDelegate(
    'webapi:NetSpendFeeFreePartnerAtms'
  ),
  netspendFeeFreePartnerAtmsMarker: acpFeatureDelegate(
    'webapi:NetspendFeeFreePartnerAtmsMarker'
  ),
  netspendPaybackLoyaltyEligible: acpFeatureDelegate(
    'webapi:netspend_payback_loyalty_eligible'
  ),
  isPointsBizEnabled: acpFeatureDelegate('isPointsBizEnabled'),
  orderAdditionalCardEligible: acpFeatureDelegate(
    'webapi:order_additional_card_eligible'
  ),
  isRewardsBizEnabled: acpFeatureDelegate('isRewardsBizEnabled'),
  premierCustomCardEligible: acpFeatureDelegate(
    'webapi:premier_custom_card_eligible'
  ),
  prescriptionSavingsCardEligible: acpFeatureDelegate(
    'webapi:prescription_savings_card_eligible'
  ),
  payNearMeUpdateRequired: acpFeatureDelegate(
    'webapi:pay_near_me_update_required'
  ),
  rentTrackEnabled: acpFeatureDelegate('webapi:rent_track_enabled'),
  sendMoneyEnabled: acpFeatureDelegate('webapi:send_money_eligible'),
  isManageContactsEnabled: acpFeatureDelegate('isManageContactsEnabled'),
  showLocationFilters: acpFeatureDelegate(
    'webapi:show_reload_location_filters'
  ),
  skipPayNearMePaywalls: acpFeatureDelegate('webapi:skip_paynearme_paywalls'),
  subaccountEnabledAccount: acpFeatureDelegate(
    'webapi:subaccount_enabled_account'
  ),
  isSidenavManageSubaccountEnabled: acpFeatureDelegate(
    'isSidenavManageSubaccountEnabled'
  ),
  isFamilyCardsAvailable: acpFeatureDelegate('isFamilyCardsAvailable'),
  sortStoreLocationsByDistance: acpFeatureDelegate(
    'webapi:sort_store_locations'
  ),
  isFAQHidden: acpFeatureDelegate('webapi:isFAQHidden'),
  isNetspendDisbursement: isNetspendDisbursement,
  isPropayEligible: acpFeatureDelegate('webapi:is_propay_eligible'),
  showPreOnboarding: acpFeatureDelegate('webapi:show_pre_onboarding'),
  showWUTransfers: acpConfigDelegate('showWUTransfers'),
  mobilePBREnabled: acpConfigDelegate('mobilePBREnabled'),
  mobileCheckLoadFeatureIntroGate: or(
    nsPermissionDelegate('hasIngoSdk'),
    nsPermissionDelegate('isDDA')
  ),
  hasIngoSdk: hasIngoSdk,
  showFeePlanPage: acpConfigDelegate('showFeePlanPage'),
  ATMFriendlyCardsEnabled: acpConfigDelegate('ATMFriendlyCardsEnabled'),
  isEmbeddedMobile: isEmbeddedMobile,
  hasMoveMoneyMenu: or(
    /* TODO clean this up inside of CARE */
    and(
      nsPermissionDelegate('isSmallBusiness'),
      nsPermissionDelegate('subaccountEnabledAccount')
    ),
    and(
      not(nsPermissionDelegate('isSmallBusiness')),
      nsPermissionDelegate('isMoveMoneyEligible')
    )
  ),
  isNsSmallBizBrand: isNsSmallBizBrand,
  isEcheckEnabled: acpFeatureDelegate('isEcheckEnabled'),
  isStatementEnabled: acpFeatureDelegate('webapi:is_statement_enabled'),
  isSmartOneUser: acpFeatureDelegate('webapi:is_smart_one'),
  isReplacementCardAvailable: acpFeatureDelegate(
    'webapi:order_replacement_card_on_lost_stolen'
  ),
  hasRestrictions: hasRestrictions,
  productUpgradeDisplayCurrentCardFeatures: acpFeatureDelegate(
    'webapi:product_upgrade_display_current_features'
  ),
  productUpgradeDisplayFeatureComparisonTableTitle: acpFeatureDelegate(
    'acp:product_upgrade_display_feature_compare_table_title'
  ),
  productUpgradeDisplayOrderCompletedIllustration: acpFeatureDelegate(
    'acp:product_upgrade_display_order_completed_illustration'
  ),
  isRepublicProductUpgradeEligible: acpFeatureDelegate(
    'republic_product_upgrade_eligible'
  ),
  isBddaEligibleAccountType: acpFeatureDelegate('bdda_eligible_account_type'),
  canCallCustomerService: acpFeatureDelegate(
    'webapi:can_call_customer_service'
  ),
  showPnmConsentPopup: acpFeatureDelegate('webapi:show_pnm_consent_popup'),
  showPnmExternalLinkDialog: acpFeatureDelegate(
    'webapi:show_pnm_external_link_dialog'
  ),
  isDirectDepositEnabled: acpFeatureDelegate('direct_deposit_enabled'),
  isBDDA: acpFeatureDelegate('webapi:is_SkylightAllAccessCheck_eligible'),
  displayDDInfo: acpFeatureDelegate('webapi:can_display_dd_info'),
  isBusinessOnboardingCompleted: acpFeatureDelegate(
    'webapi:business_onboarding_completed'
  ),
  isExpenseManagement: acpFeatureDelegate('webapi:is_expense_user'),
  isCardLostBizEnabled: acpFeatureDelegate('isCardLostBizEnabled'),
  isExpenseManagementMaster: and(
    acpFeatureDelegate('webapi:is_expense_user'),
    acpFeatureDelegate('pubweb:businessaccount')
  ),
  isExpenseManagementSub: and(
    acpFeatureDelegate('webapi:is_expense_user'),
    acpFeatureDelegate('pubweb:businesssubaccount')
  ),
  isManageFeePlanBizEnabled: acpFeatureDelegate('isManageFeePlanBizEnabled'),
  isAccountWithoutCard: isAccountWithoutCard,
  isCardManagementEnabled: acpFeatureDelegate(
    'webapi:oac4_card_management_enabled'
  ),
  isCardStatusEnabled: acpFeatureDelegate('isCardStatusEnabled'),
  hasCustomCardEnabled: acpFeatureDelegate('hasCustomCardEnabled'),
  manageAccountEnabled: acpFeatureDelegate('manageAccountEnabled'),
  hasCardsAllowed: acpFeatureDelegate('has_cards_allowed'),

  isMyCardsAvailable: acpFeatureDelegate('isMyCardsAvailable'),
  isMyPrepaidCardsAvailable: acpFeatureDelegate('isMyPrepaidCardsAvailable'),

  isSecondaryCardHolderAllowed: acpFeatureDelegate(
    'pubweb:SecondaryCardholdersAllowed'
  ),
  isNewCardHolderAllowed: acpFeatureDelegate('webapi:NewCardHolder'),
  isPaypalTransfersInfoEnabled: acpFeatureDelegate(
    'oac4_paypal_transfers_info_enabled'
  ),
  displayAccountDetailsCFPB: acpFeatureDelegate(
    'webapi:display_account_routing_flashid_numbers'
  ),
  displayActivateCardCFPB: acpFeatureDelegate('webapi:display_activate_card'),
  isFeePlanChangeAllowed: acpFeatureDelegate(
    'webapi:is_fee_plan_change_allowed'
  ),
  feePlanAndAnytimeAlertsWCAConfigEnabled: acpFeatureDelegate(
    'webapi:fee_plan_anytime_alerts_wca_config_enabled'
  ),
  displayActivateYourCard: acpFeatureDelegate(
    'webapi:display_activate_your_card'
  ),
  hasNonActivatedCard: acpFeatureDelegate('hasNonActivatedCard'),
  displayAAIntroActivateCardBubble: acpFeatureDelegate(
    'webapi:aa_intro_activate_card_bubble'
  ),
  isEligibleForAnytimeAlerts: acpFeatureDelegate(
    'webapi:is_eligible_for_anytime_alerts'
  ),
  isPartnerChannel: acpFeatureDelegate(
    'pubweb:partnerChannel_moneygram_westernUnion_showDialogBox'
  ),
  isCardControlEnabled: acpFeatureDelegate('webapi:card_control'),
  isSecurityVerified: isSecurityVerified,
  isAwardAccount: acpFeatureDelegate('pubweb:AwardAccount'),
  hasTransferNowTermsAndConditions: acpFeatureDelegate(
    'webapi:TransferNowTermsAndConditions'
  ),
  isPaycardUser: acpFeatureDelegate('webapi:is_paycard_user'),
  isTransferNowTermsAndConditions: acpFeatureDelegate(
    'pubweb:TransferNowTermsAndConditions'
  ),
  isTransferNowUsability_CallCSReasons: acpFeatureDelegate(
    'pubweb:TransferNowUsability_CallCSReasons'
  ),
  isElectronicCommunication: acpFeatureDelegate(
    'webapi:ElectronicCommunication'
  ),
  isAlertsEnrolled: acpFeatureDelegate('pubweb:AlertsEnrolled'),
  isMeToMeErrorCondition: or(
    not(acpFeatureDelegate('pubweb:CIPApproved')),
    not(acpFeatureDelegate('pubweb:ESignVisible')),
    and(
      nsPermissionDelegate('isElectronicCommunication'),
      nsPermissionDelegate('isAlertsEnrolled')
    )
  ),

  hasSecondaryCardHolders: acpFeatureDelegate('has_secondary_card_owner'),
  isCDDAEligibleSecondaryCardHolders: acpFeatureDelegate(
    'cdda_eligible_secondary_card_owner'
  ),

  isOffersOnMap: nsPermissionDelegate('isPaybackRewardsEligible'),

  isInlineOffersEnabled: acpFeatureDelegate('webapi:isInlineOffersEnabled'),
  isSidenavMoveMoneyFromToSubaccountEnabled: acpFeatureDelegate(
    'isSidenavMoveMoneyFromToSubaccountEnabled'
  ),
  isMobileStatementAvailable: acpFeatureDelegate(
    'webapi:isMobileStatementAvailable'
  ),
  isGoogleMapsGeoCodeCachingEnabled: acpFeatureDelegate(
    'webapi:isGoogleMapGeoCodeCachingEnabled'
  ),
  isStatementOptionAvailableOnDashboardForApp: acpFeatureDelegate(
    'webapi:oac4_is_mobile_statement_enabled'
  ),
  isDirectCDDA: acpFeatureDelegate('webapi:is_netnew_cdda'),
  isCDDAEligibleForOrderCard: acpFeatureDelegate(
    'webapi:display_account_routing_flashid_numbers_netnew_dda'
  ),
  isVirtualCardOrderAllowed: acpFeatureDelegate(
    'webapi:can_order_virtual_card'
  ),
  isDashboardRedesignEnabled: acpFeatureDelegate('acp:rising_tide_eligible'),
  isAchTransferAvailable: acpFeatureDelegate(
    'webapi:is_ach_transfer_available'
  ),
  isNotificationRedesignEnabled: acpFeatureDelegate(
    'acp:rising_tide_notification'
  ),
  isInterstitialsRedesignEnabled: acpFeatureDelegate(
    'acp:rising_tide_interstitials'
  ),
  isExternalBankAccountEligible: acpFeatureDelegate(
    'webapi:external_bank_account_eligible'
  ),
  isAchTransferPullMoneyEligible: acpFeatureDelegate(
    'webapi:ach_transfer_pull_money_eligible'
  ),
  isAchTransferPushMoneyEligible: acpFeatureDelegate(
    'webapi:ach_transfer_push_money_eligible'
  ),
  isConnectedBanksVisible: acpFeatureDelegate('webapi:connected_banks_visible'),
  isSpendingTrackerEnabled: acpFeatureDelegate('webapi:spending_tracker'),
  isPushNotificationEnabled: acpFeatureDelegate('acp:push_notifications'),
  isAddResumeEventForGpayStatusCheck: acpFeatureDelegate(
    'dynamic_gpay_button_enabled'
  ),
  'acp:rising_tide_eligible': acpFeatureDelegate('acp:rising_tide_eligible'),
  'AH:isDistributorAdjustmentHubConfigured': acpFeatureDelegate(
    'AH:isDistributorAdjustmentHubConfigured'
  ),
  'acp:login_mfe_eligible': acpFeatureDelegate('acp:login_mfe_eligible'),
  'acp:preonboarding_mfe_eligible': acpFeatureDelegate(
    'acp:preonboarding_mfe_eligible'
  ),
  'acp:notifications_mfe_eligible': acpFeatureDelegate(
    'acp:notifications_mfe_eligible'
  ),
  'acp:cash_rewards_mfe_eligible': acpFeatureDelegate(
    'acp:cash_rewards_mfe_eligible'
  ),
  'acp:locations_mfe_eligible': acpFeatureDelegate(
    'acp:locations_mfe_eligible'
  ),
  'acp:close_account_mfe_eligible': acpFeatureDelegate(
    'acp:close_account_mfe_eligible'
  ),
  'acp:directdeposit_mfe_eligible': acpFeatureDelegate(
    'acp:directdeposit_mfe_eligible'
  ),
  'acp:security_mfe_eligible': acpFeatureDelegate('acp:security_mfe_eligible'),
  'webapi:spending_tracker': acpFeatureDelegate('webapi:spending_tracker'),
  'webapi:file_lean_dispute_enabled': acpFeatureDelegate(
    'webapi:file_lean_dispute_enabled'
  ),
  hasM2MSunsetted: acpFeatureDelegate('acp:hasM2MSunsetted'),
  isUpdatedAlertTermsAccepted: acpFeatureDelegate(
    'webapi:AlertsTermsV2Accepted'
  ),
  isMasterAccountAndAcceptedUpdatedEsignTerms: and(
    acpFeatureDelegate('webapi:AlertsTermsV2Accepted'),
    acpFeatureDelegate('AnytimeAlertsMasterAccountManagement')
  ),
  isAltActivationMessaging: acpFeatureDelegate(
    'webapi:alternate_activation_messaging'
  ),
  showMobileDisclosuresAtTheEnd: acpFeatureDelegate(
    'webapi:show_mobile_disclosures_at_the_end'
  ),
  isMarketingPromotionSubscriptionEligible: acpFeatureDelegate(
    'acp:pn_promotion_subscription_kill_switch'
  ),
  isPushNotificationHoldAlertTypeEnabled: acpFeatureDelegate(
    'acp:hold_txn_type_pn_enabled'
  ),
  showUpgradeLearnMoreHeaderImage: acpFeatureDelegate(
    'acp:show_upgrade_learnmore_header_image'
  ),
  showUpgradeOrderSurveyHelp: acpFeatureDelegate(
    'acp:show_upgrade_order_survey_help'
  ),
  hideUpgradeLearnMoreBalanceInquiryFeeTooltip: acpFeatureDelegate(
    'acp:hide_upgrade_learnmore_balance_inquiryfee_tooltip'
  ),
  showBenefitCenterBrandBenefits: acpFeatureDelegate(
    'acp:benefit_center_show_brand_benefits'
  ),
  upgradeInterstitialHeaderLearnmoreButtonEnabled: acpFeatureDelegate(
    'acp-account-upgrade-interstitial-header-learnmore-button'
  ),
  hideBenefitsCenterDisclaimerDialog: acpFeatureDelegate(
    'acp:benefits_center_terms_conditions_header'
  ),
  hasMultipleUpgradeCardBrands: hasMultipleUpgradeCardBrands,
  isTroobaEnabled: acpFeatureDelegate('acp:suspicious_transaction_review'),
  checkOobaAfterPasswordAuth: acpFeatureDelegate(
    'acp:check_ooba_after_password_auth'
  ),
  showActivationEsignTermsOnVerifyIdentity: acpFeatureDelegate(
    'acp:show_activation_esign_terms_on_verify_identity'
  ),
  showActivationAccountAgreementTermsWithFeeText: acpFeatureDelegate(
    'acp:activation_show_account_terms_agreement_with_fee_text'
  ),
  showCongratsFeatureText: acpFeatureDelegate('acp:show_congrats_feature_text'),
  showAdditionalActivateButton: acpFeatureDelegate(
    'webapi:show_additional_activate_button_loginscreen'
  ),
  hideOobaPhoneNumber: acpFeatureDelegate(
    'acp:activation_hide_ooba_phone_number'
  ),
  isDigitalIssuanceAccount: acpFeatureDelegate(
    'acp:is_digital_issuance_account'
  ),
  isCipConditionalAlertEligible: acpFeatureDelegate(
    'webapi:cip_conditional_alert_eligible'
  ),
  isCddaEligibleAccountBalance: acpFeatureDelegate(
    'cdda_eligible_account_balance'
  ),
  isCddaEligiblePendingDispute: acpFeatureDelegate(
    'cdda_eligible_pending_dispute'
  ),
  isCddaEligibleLegalBlocks: acpFeatureDelegate('cdda_eligible_legal_blocks'),
  isMobileAppUpgradeRequire: acpFeatureDelegate(
    'acp:mobile_app_upgrade_require_dialog'
  ),
  isContactMfeEligible: acpFeatureDelegate('acp:contact_mfe_eligible'),
  isPreauthDynamicFaqEnabled: acpFeatureDelegate('preauth_dynamic_faq_enabled'),
  isCIPConditionalDocumentsNotUploaded: acpFeatureDelegate(
    'CIPConditionalDocumentsNotUploaded'
  ),
  isCIPConditionalDocumentsUploaded: acpFeatureDelegate(
    'CIPConditionalDocumentsUploaded'
  ),
  isSecureUploadMfeEligible: acpFeatureDelegate(
    'acp:secure_upload_mfe_eligible'
  ),
  isApptentiveEnabled: acpFeatureDelegate('webapi:apptentive-feedback-enabled'),
  isAdaChatbotEnabled: acpFeatureDelegate('webapi:ada_chatbot_feature_enabled'),
  isSendMoneyEnhancedUI: acpFeatureDelegate('acp:p2pcontact_enhanced_ui'),
  isLoyaltyMfeEligible: acpFeatureDelegate('acp:loyalty_mfe_eligible'),
  isContactInofMfeEligible: acpFeatureDelegate('acp:contact_info_mfe_eligible'),
  isLoginPreferencesMfeEligible: acpFeatureDelegate(
    'acp:login_preferences_mfe_eligible'
  ),
  isSecurityMfeEligible: acpFeatureDelegate('acp:security_mfe_eligible'),
  isZenithPaybackRewardsEnabled: acpFeatureDelegate(
    'zenith_payback_rewards_enabled'
  ),
  isSpectrumWalletWebSecureInboxEnabled: acpFeatureDelegate(
    'spectrum:wallet_web_secure_inbox'
  )
};
